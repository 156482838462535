<template>
  <main>
      <headerElem></headerElem>
    <h1>Formulaire Validé</h1>
  </main>
</template>
<script>
import HeaderElem from "@/components/Header";
export default {
  name: 'play'
}
</script>